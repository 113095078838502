import styled from 'styled-components'
import {
  BREAKPOINTS,
  GRID_UNIT,
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@typeform/ginger/dist/constants/design'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import HeroModule from 'components/modules/hero-module'

import ModuleContainer from '../common/section-container'

export const HeaderSpaceWrapper = styled.section`
  padding-top: ${MOBILE_HEADER_HEIGHT}px;

  ${mediaQueries.md`
    padding-top: ${HEADER_HEIGHT}px;
  `}

  ${ModuleContainer} {
    padding-top: ${GRID_UNIT * 8}px;
    padding-bottom: ${GRID_UNIT * 8}px;
  }
`

export const MediaContainer = styled.div`
  aspect-ratio: 842/793;

  ${mediaQueries.md`
    aspect-ratio: 896/678;
  `}

  > * {
    width: 100%;
    height: auto;
  }
`

// TODO: Will be removed when global approach for all site is implemented for text-wrap strategy
// See: https://typeform.atlassian.net/browse/WEB-7208
export const StyledHeroModule = styled(HeroModule)`
  p {
    text-wrap: balance;
  }
`

export const Br = styled.br`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    content: '';
    &:after {
      content: ' ';
    }
  }
`
