import ReactMarkdown, { Components } from 'react-markdown'
import React from 'react'

type TMarkdown = {
  children?: React.ReactNode
  inline?: boolean
  color?: string
}

const Markdown = ({ children, inline = false, ...rest }: TMarkdown) => {
  const components: Components = {}

  if (inline) {
    components.p = ({ node, ...props }) => <React.Fragment {...props} />
  }

  if (!children) return null
  return (
    <ReactMarkdown components={components} {...rest}>
      {children ? String(children) : ''}
    </ReactMarkdown>
  )
}

Markdown.displayName = 'Markdown'

export default Markdown
