import { NAMESPACES } from 'constants/locales'

import React, { Fragment, memo, useCallback } from 'react'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import Markdown from 'components/atoms/markdown'
import { HeroLayout } from 'components/modules/hero-module'
import useTranslation from 'next-translate/useTranslation'
import { routes } from 'utils/routes'
import { trackExperimentEvent, trackSignUp } from 'components/tracking/utils'

import useHomePageMedia from '../common/use-homepage-media'

import {
  Br,
  MediaContainer,
  HeaderSpaceWrapper,
  StyledHeroModule,
} from './hero-section.styles'

const ASSETS_PATH = {
  [LANGUAGE_CODES.english]: 'homepage/hero',
  [LANGUAGE_CODES.spanish]: 'homepage/hero/es',
}

const TRACKING_LOCATION = 'header'

const HeroSection = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)
  const ctaText = t('homepage.hero.cta.label')
  const { media, smallMedia } = useHomePageMedia({
    assetsPath: ASSETS_PATH,
    slug: 'hero',
    aboveTheFold: true,
    altText: t('homepage.hero.media.alt.text'),
  })

  const handleSignupClick = useCallback(() => {
    trackExperimentEvent({
      name: 'signup_hero_click',
    })

    trackSignUp({
      ctaText,
      itemType: 'button',
      linkUrl: routes(lang).signup,
      location: TRACKING_LOCATION,
    })
  }, [ctaText, lang])

  return (
    <HeaderSpaceWrapper data-testid='homepage-hero'>
      <StyledHeroModule
        layout={HeroLayout.MediaLeft}
        title={
          <Markdown components={{ p: Fragment, br: Br }}>
            {t('homepage.hero.title')}
          </Markdown>
        }
        body={t('homepage.hero.description')}
        media={<MediaContainer>{media}</MediaContainer>}
        smallMedia={<MediaContainer>{smallMedia}</MediaContainer>}
        actionBar={{
          primaryLink: {
            'label': ctaText,
            'href': routes(lang).signup,
            'data-testid': 'hero-signup',
          },
          onClick: handleSignupClick,
        }}
      />
    </HeaderSpaceWrapper>
  )
}

HeroSection.displayName = 'HeroSection'

export default memo(HeroSection)
