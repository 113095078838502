import React, { Suspense, memo } from 'react'
import ErrorBoundary from 'components/error-boundary'
import { AnimatedContainerNoJSReset } from 'components/atoms/animated-container'
import SuspenseFallback from 'components/suspense-fallback'
import dynamic from 'next/dynamic'

import HeroSection from './hero-section'
import TrackingLocation, {
  TRACKING_LOCATIONS,
} from './common/tracking-location'

const Partners = dynamic(() => import('./partners-section'))
const HomepageBelowFold = dynamic(() => import('./homepage-below-fold'))

const Homepage = () => {
  return (
    <>
      <AnimatedContainerNoJSReset />

      <TrackingLocation name={TRACKING_LOCATIONS.HEADER}>
        <ErrorBoundary>
          <HeroSection />
        </ErrorBoundary>

        <ErrorBoundary>
          <Suspense fallback={<SuspenseFallback />}>
            <Partners />
          </Suspense>
        </ErrorBoundary>
      </TrackingLocation>

      <Suspense fallback={<SuspenseFallback />}>
        <HomepageBelowFold />
      </Suspense>
    </>
  )
}

Homepage.displayName = 'Homepage'

export default memo(Homepage)
