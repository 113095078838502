import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { trackHomepageSectionInView } from 'components/tracking'

export const TRACKING_LOCATIONS = {
  HEADER: 'header',
  FOR_THEM: 'body:for_them',
  FOR_YOU: 'body:for_you',
  INTEGRATIONS: 'body:integrations',
  TEMPLATES: 'body:templates',
  STATS: 'body:stats',
  ENDING: 'body:ending',
}

const TrackingLocation = ({ children, inViewThreshold, name }) => {
  const handleInViewSection = useCallback(
    inView => {
      if (inView && name) {
        const documentHeight = document.body.offsetHeight - window.innerHeight
        const scrollPercentage = window.scrollY / documentHeight

        trackHomepageSectionInView({
          location: name,
          scrollPercentage: `${Math.round(scrollPercentage * 100)}%`,
        })
      }
    },
    [name]
  )

  return (
    <InView
      triggerOnce
      threshold={inViewThreshold}
      onChange={handleInViewSection}
    >
      {({ ref }) => <div ref={ref}>{children}</div>}
    </InView>
  )
}

TrackingLocation.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  inViewThreshold: PropTypes.number,
}

TrackingLocation.defaultProps = {
  inViewThreshold: 0.5,
}

TrackingLocation.displayName = 'TrackingLocation'

export default memo(TrackingLocation)
