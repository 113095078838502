import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import GridContainer from 'components/atoms/grid-container/next'

const SectionContainer = styled(GridContainer)`
  padding-left: ${GRID_UNIT * 4}px;
  padding-right: ${GRID_UNIT * 4}px;
  padding-top: ${GRID_UNIT * 8}px;
  padding-bottom: ${GRID_UNIT * 8}px;
  column-gap: ${GRID_UNIT * 3}px;
  row-gap: 0;

  ${mediaQueries.sm`
    padding-left: ${GRID_UNIT * 7.5}px;
    padding-right: ${GRID_UNIT * 7.5}px;

    padding-top: ${GRID_UNIT * 10}px;
    padding-bottom: ${GRID_UNIT * 10}px;
  `}

  ${mediaQueries.md`
    padding-left: ${GRID_UNIT * 10}px;
    padding-right: ${GRID_UNIT * 10}px;

    padding-top: ${GRID_UNIT * 12}px;
    padding-bottom: ${GRID_UNIT * 12}px;
  `}

  ${mediaQueries.lg`
    column-gap: ${GRID_UNIT * 4}px;
  `}
`

export default SectionContainer
