import { NAMESPACES } from 'constants/locales'

import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import useTranslation from 'next-translate/useTranslation'
import React, { useMemo } from 'react'
import { getStaticAsset } from 'utils/assets'
import Head from 'next/head'
import dynamic from 'next/dynamic'

const OptimizedImage = dynamic(
  () => import('components/optimized-media/optimized-image')
)
const OptimizedVideo = dynamic(
  () => import('components/optimized-media/optimized-video')
)

export type TMediaProps = {
  assetsPath: {
    // TODO: update this to use language code enums
    // when ginger module is converted to TS
    [key: string]: string
  }
  slug: string
  aboveTheFold: boolean
  altText?: string
}

const useHomePageMedia = ({
  assetsPath,
  slug,
  aboveTheFold = false,
  altText,
}: TMediaProps) => {
  const { lang } = useTranslation(NAMESPACES.HOME)

  const mediaObject = useMemo(() => {
    const localisedAssetsPath =
      assetsPath[lang] || assetsPath[LANGUAGE_CODES.english]

    const assetPath = getStaticAsset(`${localisedAssetsPath}/${slug}`)
    return {
      smallMedia: (
        <>
          {aboveTheFold && (
            <Head>
              <link
                rel='preload'
                as='image'
                href={`${assetPath}@2x.webp`}
                imageSrcSet={`${assetPath}.webp 1x, ${assetPath}@2x.webp 2x`}
              />
            </Head>
          )}
          <OptimizedImage
            src={`${assetPath}@2x.webp`}
            srcSet={`${assetPath}.webp 1x, ${assetPath}@2x.webp 2x`}
            aboveTheFold={aboveTheFold}
            alt={altText}
          />
        </>
      ),
      media: (
        <OptimizedVideo
          poster={`${assetPath}-poster.webp`}
          mp4={`${assetPath}.mp4`}
          webm={`${assetPath}.webm`}
          preload={aboveTheFold}
        />
      ),
    }
  }, [assetsPath, lang, slug, aboveTheFold, altText])

  return mediaObject
}

export default useHomePageMedia
